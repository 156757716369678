import * as React from 'react';

import LabResultsForCard from './LabResultsForCard';
import { SEOLinkWrapper } from '../routing/router-utils';
import PreviewProductImage  from './PreviewProductImage';
import ProductName from './ProductName';
import InCartCheck from './InCartCheck';
import DisplayPriceOnCard from './DisplayPriceOnCard';
import BizOwnerTooltip from '../common/BizOwnerTooltip';
import SaleLabelBottom from './SaleLabelBottom';
import SoldOutRibbon from './SoldOutRibbon';
import Spinner from '../common/Spinner';
import PropTypes from 'prop-types';

import styles from './ProductPreviewCard.module.css';

const ProductPreviewCard = ({
  Id,
  product,
  isSuggestedProduct,
  handleCartClick=()=>{},
  handleClick,
  deferImage, // for off-screen carousel cards
}) => {

  // The deep link for Google
  const deepLink = `/product/${Id}`;

  const {
    display_info,
    discount_data,
    quantity_available,
    display_sold_out_status,
    bundle_deal_data,
  } = product;

  const {
    name:Name,
    image_url,
    cost_usa_cents,
    brand,
    category,
    cannabis_type,
    lab_results } = display_info;

  const showLabResults = lab_results?.length > 0;

  // NOTE: The carousel card handleClick is applied in SimpleCarousel
  return (
    <>
      <div className={styles.productCardWrap}>
        <SEOLinkWrapper deepLink={deepLink} styleClass={styles.productCard}>
          <div className={styles.productImageWrap}>
            { deferImage
              ? <div className={styles.spinnerWrap}>
                  <Spinner inSmallLayout />
                </div>
              : <PreviewProductImage
                  src={image_url}
                  category={category.display_name}
                  productName={Name}
                  isSuggestedProduct={isSuggestedProduct} />
            }
          </div>

          <div className={styles.productBody}>
            <div className={styles.productPrice}>
              <DisplayPriceOnCard
                cost_usa_cents={cost_usa_cents}
                bundle_deal_data={bundle_deal_data}
                discount_data={discount_data} />
            </div>

            <div className={styles.productName}>
              <ProductName name={Name} cannabisType={cannabis_type} />
            </div>

            { showLabResults &&
              <div className={styles.productInfo}>
                <LabResultsForCard
                  productId={Id}
                  categoryName={category?.display_name}
                  lab_results={lab_results} />
              </div>
            }

            { brand &&
              <div className={styles.brand}>
                <span>{brand}</span>
                <BizOwnerTooltip businessName={brand} />
              </div>
            }
          </div>
          <div className={styles.addedWrapper}>
            <InCartCheck
              itemId={Id}
              product={product}
              quantity_available={quantity_available}
              display_sold_out_status={display_sold_out_status}
              handleCartClick={handleCartClick} />
          </div>
        </SEOLinkWrapper>
        <SoldOutRibbon
          display_sold_out_status={display_sold_out_status}
          quantity_available={quantity_available} />
      </div>
      <SaleLabelBottom
        product={product}
        isSuggestedProduct={isSuggestedProduct} />
    </>
  )
};

ProductPreviewCard.propTypes = {
  Id: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  isSuggestedProduct: PropTypes.bool,
  handleCartClick: PropTypes.func.isRequired, /* quick add icon */
  handleClick: PropTypes.func.isRequired,
  deferImage: PropTypes.bool, // for off-screen carousel cards
};

export default ProductPreviewCard;
