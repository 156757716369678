import * as React from 'react';

import SuperDealTooltip from './SuperDealTooltip';
import { SuperDealText, SuperDealWithLimitText, isSuperDeal } from '../products/useSaleLabelDetails';
import styles from './SuperDeal.module.css';

import PropTypes from 'prop-types';

/**
 * Display bulk price teaser and tooltip ( used in ProductCardForGrid )
 */
const SuperDealDetails = ({
  product,
  inModal,
}) => {

  const { per_order_max_quantity:limit } = product;

  return ( isSuperDeal(product)
    ? <div className={styles.detailsGrid}>
        <div className={limit ? styles.dealLabelWithLimit : styles.dealLabel}>
          { limit
            ? <SuperDealWithLimitText limit={limit} />
            : <SuperDealText />
          }
        </div>
        <div>
          <SuperDealTooltip
            product={product}
            withStyle={{marginTop:0}}
            inModal={inModal} />
        </div>
      </div>
    : null
  )
}

SuperDealDetails.propTypes = {
  product: PropTypes.object.isRequired,
  inModal: PropTypes.bool
}

export default  SuperDealDetails;
