import * as React from "react";

import { auth } from "../../firebase";
import { signInAnonymously, onAuthStateChanged, setPersistence, browserSessionPersistence } from "firebase/auth";
import { activeController, devLog } from "../util/util";
import { storeValue, getStoredValue, removeStoredValue, userAddressKey } from '../util/storage-utils';
import { fetchUserInfo } from '../registration/registration-utils';
import { SERVICE_AREA_ZIP_CODES } from '../dispensary/DispensaryProvider';
import useAddlContextTracking, { TRACK_CONTEXT } from "../analytics/useAddlContextTracking";
import { bostonNeighborhoodsByZipCode } from "../analytics/session_tracking";
import useIntercomLogging, { INTERCOM_MILESTONES } from "../intercom/useIntercomLogging";
import { trackEvent, trackError } from "../analytics/tracking";
import PropTypes from 'prop-types';

export const UserContext = React.createContext({ user: null });

const FETCH_DELAY_MS = 500;

/**
 *  There should always be a real signed-in user or an "anonymous" user
 *  as a token is required with (most) API requests.
 */
const UserProvider = ({children}) => {

  // Menu app: iframed Incognito will fail on 'local' setting!
  setPersistence(auth, browserSessionPersistence); /* local or session */

  const { logIntercomAction } = useIntercomLogging();
  const { trackEventWithContext } = useAddlContextTracking();
  const [user, setUser] = React.useState();
  const [zypRunUser, setZypRunUser] = React.useState();
  // 404 on fetch etc.
  const [userError, setUserError] = React.useState();
  // Location entered via an address prompt
  const [searchLocation, setLocalSearchLocation] = React.useState();  /* e.g. from address prompt */
  // No location found in localStorage, so show address prompts
  const [hasNoLocation, setHasNoLocation] = React.useState();
  // Email OptIn
  const [emailOptIn, setEmailOptIn] = React.useState();
  // SMS OptIn
  const [textOptIn, setTextOptIn] = React.useState();
  // Delivery SMS OptIn
  const [deliveryTextOptIn, setDeliveryTextOptIn] = React.useState();
  // Discreet Delivery
  const [discreetEnabled, setDiscreetEnabled] = React.useState();
  // Track Boston sessions
  const DELIVERABLE_SESSION_LABEL = 'session_start_deliverable';
  // "session_start" tracking is handled by Firebase,
  // We have our own variant since we want to include additional "Custom Dimension" data
  const SESSION_START_LABEL = 'session_start_zr';
  const SESSION_START_PARAM = 'session_start_'; // Use only with suffix
  // Berbix ID verification status
  // const [isIdVerified, setIsIdVerified] = React.useState();

  const controllerRef = React.useRef(new AbortController());

  // Track Boston sessions - To understand the % of traffic that could actually order delivery
  const trackDeliverableSession = React.useCallback((location) => {
    const { city, state, zip_code }  = location || {};
    const cityLabel = (city || '').toLowerCase().replace(/\s/g, '');
    if (zip_code && searchLocation?.zip_code !== zip_code) {
      if (SERVICE_AREA_ZIP_CODES.includes(zip_code)) {
        trackEvent(DELIVERABLE_SESSION_LABEL);
      }
      trackEventWithContext(SESSION_START_LABEL, [TRACK_CONTEXT.ONEHOUR]);
      // Track all city sessions for MA
      if (city && state?.toLowerCase() === 'ma') {
        trackEvent(`${SESSION_START_PARAM}${cityLabel}`);
        // Track Boston neighborhood sessions
        if (bostonNeighborhoodsByZipCode.has(zip_code)) {
          const nabe = bostonNeighborhoodsByZipCode.get(zip_code);
          trackEvent(`${SESSION_START_PARAM}${nabe.trackingLabel}`);
        }
      // Else just track state
      } else if (state) {
        trackEvent(`${SESSION_START_PARAM}${state.toLowerCase()}`);
      }
    }
  }, [searchLocation, trackEventWithContext]);

  /**
   * Set all the registered user info from the DB on session start/update
   */
  const userInfoCallback = React.useCallback((response) => {
    devLog('User Info callback');
    if (response.error) {
      setUserError(
        `(${response.status}) ${response.error}: ${auth.currentUser?.displayName}`
      );
    } else {
      devLog('Setting registered user info...');
      setUserError(null);
      setZypRunUser(response);
      setHasNoLocation(false);
      setEmailOptIn(!!response.marketing_communications.email_opted_in);
      setTextOptIn(!!response.marketing_communications.sms_text_opted_in);
      setDeliveryTextOptIn(!!response.real_time_order_communications.sms_text_opted_in);
      setDiscreetEnabled(!!response.prefers_discreet_delivery);
      trackDeliverableSession(response.location);
    }
  }, [trackDeliverableSession]);

  const refetchUser = () => {
    fetchUserInfo(userInfoCallback, new AbortController());
  };

  // Clear state when user logs out
  const flushUserInfo = React.useCallback(() => {
    // We are only clearing state, not the local storage address (for anonymous users)
    setLocalSearchLocation(undefined);
    setZypRunUser(undefined);
    setEmailOptIn(undefined);
    setTextOptIn(undefined);
    setDeliveryTextOptIn(undefined);
    // setIsIdVerified(undefined);
  }, []);

  // For canceling anonymous user creation
  const timerRef = React.useRef();

  // Create an anonymous user if Firebase doesn't restore the registered use in 500ms
  React.useEffect(() => {
    const controller = activeController(controllerRef);
    const unsubscribe = onAuthStateChanged(auth, async newUser => {
      devLog(`USER STATE CHANGE: ${newUser ? newUser.isAnonymous ? 'anonymous' : 'registered' : 'null'}`);
      // Clear all queued user info requests
      window.clearTimeout(timerRef.current);

      // If newUser is null, create an anonymous user so we can make API requests
      if ( newUser === null ) {
        timerRef.current = window.setTimeout(() => {
          devLog('null user: creating anonymous user');
          createAnonymousUser(() => setUser(auth.currentUser));
          flushUserInfo();
        }, FETCH_DELAY_MS);
      } else {
        // Fetch ZR registered user data
        setUser(newUser);
        timerRef.current = window.setTimeout(() => {
          devLog('registered user: fetching user info');
          fetchUserInfo(userInfoCallback, controller);
        }, FETCH_DELAY_MS);
      }
    });
    // remove onAuthStateChanged on unmount
    return () => {
      controller.abort();
      unsubscribe();
    }
  }, [ user, flushUserInfo, userInfoCallback ]);

  // Log Registration_Complete with Intercom if we have an email address
  React.useEffect(() => {
    if (user?.email) {
      logIntercomAction(INTERCOM_MILESTONES.REGISTRATION_COMPLETE);
    }
  }, [user, logIntercomAction]);

  // Check localStorage for an address (anonymous users)
  React.useEffect(() => {
    // Allow some time for registered user restore
    window.setTimeout(() => {
      if(user && user.isAnonymous && !searchLocation) {
        devLog('checking storage for search location');
        const storedLocation = getStoredValue(userAddressKey);
        if (storedLocation && storedLocation.indexOf('street_address') > 0) {
          const storedLoc = JSON.parse(storedLocation);
          setLocalSearchLocation(storedLoc);
          trackDeliverableSession(storedLoc);
        } else {
          setHasNoLocation(true);
        }
      }
    }, FETCH_DELAY_MS);
  }, [user, searchLocation, trackDeliverableSession]);

  /**
   * We generally want at least an anonymous user so we have a JWT token to pass in requests
   *
   * @param {callback} callback
   */
  const createAnonymousUser = (callback) => {
    devLog('create anonymous user!');
    signInAnonymously(auth)
      .then(data => {
        devLog('anonymous user created');
        callback();
      })
      // Anonymous auth is enabled in the Firebase Console
      .catch(error => {
        trackError('error_creating_anon_user');
        // Lets determine anon user errors
        const details = error.message?.replace('Firebase: Error (', '');
        trackError(`error_anon_${details}`);
        devLog(`error creating anonymous user ${error.message}`);
      });
  };

  /**
   * Set searchLocation based on a user's address input
   *
   * The registration-based location is preferred over the searchLocation
   *
   * @param {object} newLocation
   */
  const setSearchLocation = (newLocation) => {
    try {
      if (newLocation && JSON.stringify(newLocation) !== JSON.stringify(searchLocation)) {
        setLocalSearchLocation(newLocation);
        storeValue(userAddressKey, JSON.stringify(newLocation));
        setHasNoLocation(false);
        trackDeliverableSession(newLocation);
      }
    } catch(e) {
      trackError('error_setting_search_location');
    }
  }

  /**
   * Clear searchLocation on Log Out etc.
   */
  const clearSearchLocation = () => {
    setLocalSearchLocation(undefined);
    removeStoredValue(userAddressKey);
    setHasNoLocation(true);
  }

  return (
    <UserContext.Provider value={{
      /* The Firebase user, possibly anonymous */
      user,
      searchLocation,
      hasNoLocation,   /* e.g. anonymous user and no stored location */
      setSearchLocation,
      clearSearchLocation,
      /* Registered User data */
      userId: user?.uid,
      userEmail: user?.email,
      userName: zypRunUser?.name,
      userPhone: zypRunUser?.tel_number,
      userBirthday: zypRunUser?.dob,
      location: zypRunUser?.location,
      hasCompletedFirstOrder: zypRunUser?.has_completed_first_order,
      hasCompletedFirstAeropayOrder: zypRunUser?.has_completed_first_aeropay_order,
      completedOrderCount: zypRunUser?.order_completed_count,
      currentLocation: zypRunUser?.location || searchLocation,
      isIdVerified: false, // Berbix not in use
      emailOptIn,
      textOptIn,
      deliveryTextOptIn,
      discreetEnabled,
      /* refetch after updates on account page, etc. */
      refetchUser,
      userError
    }}>
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
};

export default UserProvider;
