import { dollarDisplay } from '../providers/OrderPricingProvider';
import { fetchProducts } from '../dispensary/dispensary-utils';
import { trackError } from '../analytics/tracking';

// Placeholder images (black and white) for products without images
// In static directory to prevent bundle bloat
export const DefaultImages = {
  'Accessories': '/img/accessories_stock80.jpg',
  'Concentrate': '/img/concentrates_stock80.jpg',
  'Concentrates': '/img/concentrates_stock80.jpg',
  'Edibles': '/img/edibles_stock80.jpg',
  'Buds': '/img/flower_stock80.jpg',
  'Flower': '/img/flower_stock80.jpg',
  'Pre Rolls': '/img/preroll_stock80.jpg',
  'Pre-Rolls': '/img/preroll_stock80.jpg',
  'PreRolls' : '/img/preroll_stock80.jpg',
  'Tincture' : '/img/tinctures_stock80.jpg',
  'Tinctures' : '/img/tinctures_stock80.jpg',
  'Topicals': '/img/topicals_stock80.jpg',
  'Vaporizers' : '/img/vaporizers_stock80.jpg',
};

// If we don't have a real product image, use the fallback
// The fallback is needed for CDN failure so we return it as well
export const handleMissingProductImage = (image_url, product_category) => {
  let imgUrl = image_url,
      fallbackUrl = DefaultImages[product_category],
      isFallback = false;
  if (!image_url) {
    imgUrl = fallbackUrl;
    isFallback = true;
  }
  return { imgUrl, fallbackUrl, isFallback };
}

// Determine if product Image is a Category fallback image
export const isProductFallbackImage = (src) => {
  return src &&
         (src.indexOf('/static/media/') === 0 ||
          (src.indexOf('/static/media/') > 0 && src.indexOf('ziprun') > 0) ||
          src.indexOf('data:image') === 0);
};

// We only display 1/8, 1/4, 1/2, 1oz - Used in product modal
export const bulkFractionWhitelist = [3.5, 7, 14, 28];

// Name, desc, price are guaranteed if product.is_active
export const isValidProduct = (product) => {
  /**
   * NOTE: We currently request only "active" products
   * so no need to check .is_active
   * We SHOULD make sure the price is not accidentally
   * set to 0 though...
   *
   * Min price: $1.00
   */
  /**
   *  420 Dutchie back office issues (2024), suppress sold out products
   *
   * const suppressed = [
   *   "79161",
   * ];
   * if (suppressed.includes(product.id)) {
   *   return false;
   * }
   */
  return product.display_info &&
         parseInt(product.display_info.cost_usa_cents, 10) > 99;
};

// Ensure a sort order for product comparison
const categorySortOrder = (sampleProduct) => {
  return typeof sampleProduct.display_info.category?.sort_order === 'number'
    ? sampleProduct.display_info.category.sort_order
    : 99;
}

// Handling .display_info only currently
export const flattenedProduct = (product) => {
  return product
    ? Object.assign(product, product.display_info)
    : {};
}

/**
 * Display "is_featured" products using product.display_priority
 */
const productDisplayComparator = (productA, productB) => {
  const aPriority = productA.is_featured ? productA.display_priority || 98 : 99;
  const bPriority = productB.is_featured ? productB.display_priority || 98 : 99;
  return aPriority === bPriority ? 0 : aPriority < bPriority ? -1 : 1;
}

/**
 * Validate and map products into categories supported by the app
 *
 * Retrieving products by category is currently the primary way we display products
 * We validate products prior to putting them into the category map
 *
 * @param {array} products - the fetched products
 * @param {string} brand - optional brand filter
 */
export const groupByStandardCategories = (products) => {
  // Featured products first
  products.sort(productDisplayComparator);

  // Return a Map by category in sorted key order
  // Map products by category
  const mapByCatName = (products || []).reduce(function(map, item) {
    if (isValidProduct(item) &&
        item.display_info.category?.display_name) {
      const cat = item.display_info.category.display_name;
      const products = map.get(cat) || [];
      products.push(item);
      map.set(cat, products);
    }
    return map;
  }, new Map());

  // Sort keys into desired category sort order
  const arrayByCatName = Array.from(mapByCatName.entries());
  arrayByCatName.sort((cat1,cat2) => {
    // sort_order is set to 99 if missing ( applied previously as well )
    const cat1Order = categorySortOrder(cat1[1][0]);
    const cat2Order = categorySortOrder(cat2[1][0]);
    return cat1Order === cat2Order ? 0 : cat1Order > cat2Order ? 1 : -1;
  });

  // 9/18/23 - Let's use a real Map
  return new Map(arrayByCatName);
}

// Product has sale price or bulk pricing
const hasSaleOrBulkPricing = (product) => {
  return product.discount_data?.cost_usa_cents_discounted > 0 ||
         product.bundle_deal_data?.values.length
};

/**
 * Validate and map SALE products into categories supported by the app
 * ( Same as groupByStandardCategories but includes only sale products )
 * Retrieving products by category is currently the primary way we display products
 * We validate products prior to putting them into the category map
 *
 * @param {array} products - the fetched products
 * @param {string} brand - optional brand filter
 */
export const groupSaleProductsByStandardCategories = (products) => {
  // Featured products first
  products.sort(productDisplayComparator);

  // Map by category
  const mapByCatName = (products || []).reduce(function(map, item) {
    if (isValidProduct(item) &&
        hasSaleOrBulkPricing(item) &&
        item.display_info.category?.display_name) {
      const cat = item.display_info.category.display_name;
      const products = map.get(cat) || [];
      products.push(item);
      map.set(cat, products);
    }
    return map;
  }, new Map());

  // Sort keys into desired sort order
  const arrayByCatName = Array.from(mapByCatName.entries());
  arrayByCatName.sort((cat1,cat2) => {
    // sort_order is set to 99 if missing ( applied previously as well )
    const cat1Order = categorySortOrder(cat1[1][0]);
    const cat2Order = categorySortOrder(cat2[1][0]);
    return cat1Order === cat2Order ? 0 : cat1Order > cat2Order ? 1 : -1;
  });

  return  Object.fromEntries(arrayByCatName);
}

export const loadDispensaryProducts = (fetchOptions, productsCallback, abortController) => {
  const loadProductsCallback = (products) => {
    productsCallback(products);
  };

  if (fetchOptions) {
    try {
      fetchProducts(fetchOptions, loadProductsCallback, abortController);
    } catch(error) {
      trackError(`error_fetching_products`);
    }
  }
}

/* PRODUCT PRICING - FOR BROWSING, NOT ORDER SUBMISSION CALCS */

// Regular sale pricing
export const discountedItemTotalCents = (item, quantity) => {
  const { cost_usa_cents_discounted } = item?.discount_data || {};
  if (cost_usa_cents_discounted) {
    return cost_usa_cents_discounted * quantity;
  }
  return undefined;
}

// Bundle/bulk pricing
export const bundleItemTotalCents = (item, quantity) => {
  const { bundle_deal_data } = item;
  // Bundle quantity price trumps ALL
  if (bundle_deal_data && quantity >= bundle_deal_data.minimum_quantity) {
    const bundleMatch = [ ...bundle_deal_data.values]
                            .sort((a,b) => b.quantity - a.quantity) // sort descending
                            .find(val => quantity >= val.quantity);
    if (bundleMatch) {
      return bundleMatch.unit_price * quantity;
    }
  }
  return undefined;
}

// Item total for an original price or sale price/bundle item
// (uncarted, pre-multi-product bundle pricing)
const itemTotalForQuantity = (item, quantity) => {
  // Bundle quantity price trumps ALL
  if (quantity > 1 && item.bundle_deal_data?.values) {
    const bundlePriceCents = bundleItemTotalCents(item, quantity);
    if (bundlePriceCents) {
      return bundlePriceCents;
    }
  }
  // Check for Sale Price next
  const discountedItemTotal = discountedItemTotalCents(item, quantity);
  if (discountedItemTotal) {
    return discountedItemTotal;
  } else {
    // Return regular price
    return item.display_info.cost_usa_cents * quantity;
  }
};

// For add-to-cart button
export const getCartItemTotalDisplay = (product, quantity=1) => {
  return dollarDisplay(itemTotalForQuantity(product, quantity));
};

// Suppress cents if they are 0.
export const getShortenedPriceDisplay = (price_in_cents) => {
  const inDollars = price_in_cents % 100 === 0;
  return dollarDisplay(price_in_cents, inDollars);
};

// 4 for $120
export const getBundlePriceTeaser = (bundle_deal_data) => {
  const { values } = bundle_deal_data || {};
  if (values?.length) {
    const { unit_price, quantity } = values[0];
    return `${quantity} for ${getShortenedPriceDisplay(unit_price * quantity)}`;
  }
  return undefined;
}
