import * as React from 'react';

const StrongText = ({
  fontWeight=600,
  brandColor,
  noWrap,
  noMargin,
  firstWord,
  onClick,
  children,
}) => {

  const textStyle = {
    cursor: onClick ? 'pointer' : 'default',
    textDecoration: onClick ? 'underline' : 'none',
    fontWeight,
    color: brandColor ? 'var(--zrPrimaryDark)' : 'inherit',
    whiteSpace: noWrap ? 'nowrap' : 'normal',
    margin: noMargin
      ? 0
      : firstWord
        ? '0 .25rem 0 0'
        : '0 .25em',
  }

  return (
    <span onClick={onClick || undefined} style={textStyle}>{children}</span>
  );
}

export default StrongText;
