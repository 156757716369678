import { isValidProduct } from "../products/product-utils";
import { getBundlePriceTeaser } from '../products/product-utils';

// No minimum ATM, show carousels with 1 product
const BUNDLE_CAROUSEL_PRODUCT_MINIMUM = 1;

// Confirm product has bundle pricing
const hasBundlePricing = (product) => {
  return product.bundle_deal_data?.values.length;
};

// Order carousels by price, lowest to highest
// Item price is FIRST threshold price: 2for30, 3for40
const bundlePrice = (bundleData) => {
  // Push single-item bundles to bottom via price:100000
  if (bundleData[1].length < 2) {
    return 100000;
  }
  return bundleData[1][0].bundle_deal_data.values[0].unit_price;
}

// Map category name by ID ("4FOR120" ETC.)
// Assumption: bundle is all same-category
export const mapCategoryNameByBundleId = (products) => {
  // Map by category
  const categoryByBundleId = (products || []).reduce(function(map, item) {
    if (isValidProduct(item) &&
        hasBundlePricing(item)) {
      const bundleId = item.bundle_deal_data.id;
      const hasCategory = map.get(bundleId);
      if (!hasCategory) {
        map.set(bundleId, item.display_info.category.display_name);
      }
    }
    return map;
  }, new Map());
  return categoryByBundleId;
}

// Map bundle deal info by category name:
// "Flower": [{
//    bundleId:"4FOR120",
//    teaserText: "4 for $120"
//    unitPrice: 3000,
// },...]
// Assumption: bundle products are all same-category
export const mapBundleDetailsByCategoryName = (products) => {
  // Map by category
  const bundleInfoByCategory = (products || []).reduce(function(map, item) {
    if (isValidProduct(item) &&
        hasBundlePricing(item)) {
      const category = item.display_info.category.display_name;
      const bundleId = item.bundle_deal_data.id;
      const deals = map.get(category) || [];
      if (!deals.find(deal => deal.bundleId === bundleId)) {
        const teaserText = getBundlePriceTeaser(item.bundle_deal_data);
        const unitPrice = item.bundle_deal_data.values[0].unit_price;
        deals.push({bundleId, teaserText, unitPrice});
        map.set(category, deals);
      }
    }
    return map;
  }, new Map());

  // Sort entries: cheapest unit price first
  const entries = Array.from(bundleInfoByCategory.entries());
  entries.forEach(category => {
    category[1].sort((a, b) => a.unitPrice - b.unitPrice);
  })
  return new Map(entries);
}

// Map bundle deal products by ID ("4FOR120" ETC.)
export const mapDealProductsByBundleId = (products) => {
  // Map by category
  const mapByBundleId = (products || []).reduce(function(map, item) {
    if (isValidProduct(item) &&
        hasBundlePricing(item)) {
      const bundleId = item.bundle_deal_data.id;
      const products = map.get(bundleId) || [];
      products.push(item);
      map.set(bundleId, products);
    }
    return map;
  }, new Map());

  const filteredMapByBundleId = new Map(
    Array.from(mapByBundleId.entries())
      .filter(([id, products]) => products.length >= BUNDLE_CAROUSEL_PRODUCT_MINIMUM)
  );

  // Sort keys into desired sort order
  const arrayByCatName = Array.from(filteredMapByBundleId.entries());
  arrayByCatName.sort((id1, id2) => {
    // Let's sort lowest price first: 2FOR40, 2FOR50, 2FOR60
    const bundle1Price = bundlePrice(id1);
    const bundle2Price = bundlePrice(id2);
    return bundle1Price === bundle2Price ? 0 : bundle1Price > bundle2Price ? 1 : -1;
  });

  return Object.fromEntries(arrayByCatName);
}
