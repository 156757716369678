import * as React from "react";

import { isSuperDeal } from "../products/useSaleLabelDetails";
import { CartItemsContext } from "../checkout/CartItemsProvider";
import { DISCOUNT_CODE_WARNING, REFERRAL_CREDIT_WARNING } from "../bundles/BulkCartWarning";
import Alert from "@material-ui/lab/Alert";
import StrongText from "../common/StrongText";

import PropTypes from 'prop-types';

const SuperDealCartWarning = ({
  discountCode,
  activeCredit,
  withClass}) => {

  // Super deal products
  const [dealProducts, setDealProducts] = React.useState();
  const { cartItems, itemTotal } = React.useContext(CartItemsContext);

  React.useEffect(() => {
    const dealItems = cartItems?.filter(item => isSuperDeal(item));
    setDealProducts(dealItems);
  }, [discountCode, cartItems, itemTotal]); // cartItems is a ref!

  return ( (activeCredit || discountCode) && dealProducts?.length
    ? <Alert severity="warning" className={withClass ? withClass : ''}>
        <div>
          { discountCode
              ? DISCOUNT_CODE_WARNING
              : REFERRAL_CREDIT_WARNING
          }
          {' '}cannot be used on orders that contain
          {' '}<StrongText noWrap noMargin>Super Deals.</StrongText>
          {' '}To place your order, remove the
          { discountCode
              ? ' promo code'
              : ' referral credit'
          }
          {' '}OR remove the following Super Deal products
          from your cart:
        </div>
        <table style={{ marginTop:6, width: '100%'}}>
          <tbody>
            <tr>
              <th style={{textAlign:'left'}}>Super Deal</th>
            </tr>
            { dealProducts.map(product => (
              <tr key={product.id}>
                <td>{product.display_info.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Alert>
    : null
  );
};

SuperDealCartWarning.propTypes = {
  activeCredit: PropTypes.object,
  discountCode: PropTypes.string,
  withClass: PropTypes.string,
}

export default SuperDealCartWarning;
