import * as React from 'react';

import useSaleLabelDetails, { LABEL_TYPE } from './useSaleLabelDetails';
import BundlePricingTooltip from '../bundles/BundlePricingTooltip';
import SuperDealTooltip from '../deals/SuperDealTooltip';
import PropTypes from 'prop-types';
import styles from './ProductPreviewCard.module.css';

/**
 *  "25% Off!" sale label for product cards
 *  NOTE: % must be divisible by 5 or we fall back to
 *  "dollar discount" display: $6 Off!
 *
 *  These mutually exclusive additions trump sale price display:
 *  1) Bundle pricing (bunlde_deal_data)
 *  2) Limit 1 (per_order_max_quantity)
 *  3) Super Deals (is_discount_code_restricted)
 *  4) Super Deals with Limit 1 setting/tag
 */
const SaleLabelBottom = ({
  product,
  isSuggestedProduct=false}) => {

  const { saleLabel, saleLabelType, saleLabelClass, withTooltip } = useSaleLabelDetails(product);

  // For the tooltips
  const { cost_usa_cents } = product.display_info;
  const { bundle_deal_data } = product;

  const tipWrapStyle = {
    margin: '0 0 8px 12px'
  }

  return ( saleLabel
    ? <div className={withTooltip ? styles.saleLabelWithTooltip : ''}>
        { saleLabelType === LABEL_TYPE.BUNDLE &&
          <BundlePricingTooltip
            cost_usa_cents={cost_usa_cents}
            bundle_deal_data={bundle_deal_data}
            withStyle={tipWrapStyle}
            inModal={isSuggestedProduct} />
        }
        { [LABEL_TYPE.SUPER, LABEL_TYPE.SUPER_WITH_LIMIT].includes(saleLabelType)  &&
          <SuperDealTooltip
            product={product}
            withStyle={tipWrapStyle}
            inModal={isSuggestedProduct} />
        }
        <div className={`${styles.saleLabel} ${saleLabelClass}`}
             data-is-suggested={isSuggestedProduct}>
          {saleLabel}
        </div>
      </div>
    : null
  )
}

SaleLabelBottom.propTypes = {
  product: PropTypes.object.isRequired,
  isSuggestedProduct: PropTypes.bool,
}

export default SaleLabelBottom;
