import * as React from 'react';

import { navigateTopWithState } from '../routing/router-utils';
import { getBundlePriceTeaser } from '../products/product-utils';
import { BundleDealsContext } from './BundleDealsProvider';
import  ProductCarousel from '../home/ProductCarousel';
import Button from '../styleguide/Button';
import Alert from '@material-ui/lab/Alert';
import Spinner from '../common/Spinner';

import styles from './BundleDealsPage.module.css';

// Revert to ALL bundle deals
const ViewAllDeals = ({
  buttonText="Shop ALL Mix & Match Deals",
  onClick
}) => (
  <Button
    isCentered
    withClass={styles.seeAllButton}
    text={buttonText}
    handleClick={onClick} />
);

const BundleDealsCarousels = ({
  bundleId,
  returnToPath
}) => {

  const { productsByBundle, categoryNameByBundle } = React.useContext(BundleDealsContext);
  const [displayBundles, setDisplayBundles] = React.useState();

  const viewAllClick = React.useCallback(() => {
    navigateTopWithState(
      '/mixMatch',
      { returnToPath }
    );
  }, [returnToPath]);

  // Use all bundles or bundle from path
  React.useEffect(() => {
    if (productsByBundle) {
      // BundleId in path
      if (bundleId) {
        const products = productsByBundle[bundleId] || []; // bundleId not found.
        if (products.length) {
          const singleBundle = {};
          singleBundle[bundleId] = products;
          setDisplayBundles(singleBundle);
        } else {
          // Display not found messaging
          setDisplayBundles({});
        }
      } else {
        setDisplayBundles(productsByBundle);
      }
    }
  }, [productsByBundle, bundleId])

  return ( displayBundles && categoryNameByBundle
    ? Object.values(displayBundles).length > 0
      ? <>
          { Object.keys(displayBundles).map(bundleId => {
              const bundleProducts = displayBundles[bundleId];
              return <ProductCarousel
                  key={bundleId}
                  carouselTitle={`${categoryNameByBundle.get(bundleId)}: Any ${getBundlePriceTeaser(bundleProducts[0].bundle_deal_data)}`}
                  categoryCount={bundleProducts.length}
                  carouselProducts={bundleProducts} />
          })}
          { bundleId && Object.values(productsByBundle).length > 1
            ? <ViewAllDeals onClick={viewAllClick} />
            : null
          }
        </>
      : Object.keys(displayBundles).length === 0
        ? <div className={styles.notFoundWrapper}>
            <Alert severity='warning'>
              Sorry! It looks like we're currently sold out
              of our { bundleId ? bundleId : '' } Mix & Match products.
            </Alert>
            <ViewAllDeals onClick={viewAllClick} />
          </div>
        : null
    : <Spinner />
  )
}

export default BundleDealsCarousels;
