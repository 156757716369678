import * as React from 'react';

import SuperDealTooltip from './SuperDealTooltip';
import { isSuperDeal } from '../products/useSaleLabelDetails';
import usePricingDetailsForModalStyles from '../products/usePricingDetailsForModalStyles';
import PropTypes from 'prop-types';

import dealStyles from './SuperDeal.module.css';

const SuperDealTextForModal = () => (
  <span className={dealStyles.superDeal}>
    <span className={dealStyles.emoji} role="img" aria-label="smile">🤑</span>
    Super Deal!
    <span className={dealStyles.emoji} role="img" aria-label="smile">🤑</span>
  </span>
)

const SuperDealWithLimitTextForModal = ({limit}) => (
  <span className={dealStyles.superDeal}>
    Super Deal!
    <span className={dealStyles.qtyLimitPill}>Limit {limit}</span>
  </span>
)

/**
 * Display Super Deal label and tooltip
 */
const SuperDealDetailsForModal = ({
  product,
}) => {

  const classes = usePricingDetailsForModalStyles();
  const { per_order_max_quantity } = product;

  return (isSuperDeal(product)
    ? <div className={classes.grid}>
        <div className={classes.tipWrap}>
          <SuperDealTooltip
            product={product}
            inModal={true} />
        </div>
        <div className={classes.pricingMessage}>
          { per_order_max_quantity
            ? <SuperDealWithLimitTextForModal limit={per_order_max_quantity} />
            : <SuperDealTextForModal />
          }
        </div>
      </div>
    : null
  )
}

SuperDealDetailsForModal.propTypes = {
  product: PropTypes.object.isRequired,
}

export default  SuperDealDetailsForModal;
