import * as React from 'react';

import config from '../../config';
import { UserContext } from '../providers/UserProvider';
import { DispensaryContext } from '../dispensary/DispensaryProvider';
import { DeliveryOptionsContext } from '../providers/DeliveryOptionsProvider';
import { ModalType, ModalContext } from '../modal/ModalProvider';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import ArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import DisplayCurrentSlotETABar from '../timeSlots/DisplayCurrentSlotETABar';
import ReferCalloutInHeader from '../refer/ReferCalloutInHeader';
import PriorityCalloutInHeader from '../priorityDelivery/PriorityCalloutInHeader';
import ErrorIcon from '@material-ui/icons/ErrorOutlineRounded';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import PropTypes from 'prop-types';

import styles from '../common/PageHeader.module.css';

// The width below which the deliverTo bar displays
// separately below the nav
export const DELIVER_TO_BAR_THRESHOLD = 1024;

const DeliverTo = ({isInline}) => {

  const { enabledDeliveryZipCodes, serviceAreaZipCodes, priorityETA } = React.useContext(DispensaryContext);
  const { currentLocation } = React.useContext(UserContext);

  const { isBlockedAddress } = React.useContext(DeliveryOptionsContext);

  // display address update modal on pre-registered user/temp address click
  const { displayModal } = React.useContext(ModalContext);

  const pinStyle = {
    verticalAlign: -5,
    fontSize: 20,
    marginRight: 1
  };

  const arrowStyle = {
    verticalAlign: -4,
    fontSize: 16,
    marginLeft: -4
  };

  // Unable to deliver, modal link icon
  const errorStyle = {
    cursor: 'pointer',
    verticalAlign: -6,
    fontSize: 22,
    color: 'var(--zrTextRed)'
  };

  // Blocked address
  const warningStyle = {
    cursor: 'pointer',
    verticalAlign: -6,
    fontSize: 22,
    color: '#ff9800'
  };

  const AddressLink = () => (
    <>
      <LocationOnRoundedIcon style={pinStyle} />
      Deliver to {' '}
      <span className={styles.toAddress}
        onClick={() => displayModal(
          ModalType.ADDRESS, {
            trackContext: 'address_from_nav'
          })}>
        { currentLocation?.street_address
          ? currentLocation.street_address
          : <span className={styles.addAddress}>Add Address</span>
        }
        {' '}<ArrowDownRoundedIcon style={arrowStyle} />
      </span>
      { isBlockedAddress
        ? <span onClick={() => displayModal(
            ModalType.ADDRESS_BLOCKED, {
            trackContext: 'address_blocked_nav'})}>
            <WarningIcon style={warningStyle} />
          </span>
        : currentLocation
          ? !serviceAreaZipCodes.includes(currentLocation?.zip_code)
            ? <span onClick={() => displayModal(
                  ModalType.NO_DELIVERY, {
                  trackContext: 'no_delivery_nav'})}>
                <ErrorIcon style={errorStyle} />
              </span>
            : !enabledDeliveryZipCodes.includes(currentLocation?.zip_code)
              ? <span onClick={() => displayModal(
                    ModalType.SERVICE_PAUSED, {
                    trackContext: 'service_paused_nav'})}>
                  <ErrorIcon style={errorStyle} />
                </span>
              : <></>
          : <></>
      }
    </>
  )

  const wrapperClass = isInline ? styles.deliverToInline : styles.deliverToBar;

  return (
    /* inline or bar */
    <div className={wrapperClass} id={isInline ? 'delto_inline' : 'delto_bar' }>
      { priorityETA && config.ENABLE_PRIORITY_HEADER_PROMO
        ? <PriorityCalloutInHeader priorityETA={priorityETA} />
        : <ReferCalloutInHeader />
      }
      <div className={styles.addressWrap}>
        <AddressLink />
      </div>
      {/* visible only on Mobile /signUp page */}
      <DisplayCurrentSlotETABar />
    </div>
  )
}

DeliverTo.propTypes = {
  isInline: PropTypes.bool
}

export default DeliverTo;
