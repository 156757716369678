import * as React from 'react';

import BundleDealsProvider from './BundleDealsProvider';
import BundleDealsCarousels from './BundleDealsCarousels';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import PageTitle from '../common/PageTitle';
import CurrentTimeSlotProvider from '../timeSlots/CurrentTimeSlotProvider';
import GenericHeader from '../common/GenericHeader';

import styles from './BundleDealsPage.module.css';

const BundleDealsPage = ({
  bundleId,  /* optional, from router */
  location
}) => {

  // Capture any back link
  const [returnToPath, setReturnToPath] = React.useState("/");
  React.useEffect(() => {
    const {
      returnToPath:backPath,
    } = location?.state || {};
    if (backPath) {
      setReturnToPath(backPath);
    }
  }, [location]);

  return (
    <BodyWrapper pageLayout={LayoutTypes.Default} withStyle={{marginBottom:'2rem'}}>
      <PageTitle title="Mix & Match Bundle Deals" />
      <GenericHeader
         headerTitle="Mix & Match"
         returnToPath={returnToPath} />
      <div className={styles.mixDetails}>
        <span className={styles.boomIcon} role="img" aria-label="explosion">💥</span>
        <b>Mix it up!</b> Get the low bulk price by combining
        same-carousel products to meet the bulk-price minimum!
      </div>
      <CurrentTimeSlotProvider>
        <BundleDealsProvider>
          <div className={styles.carouselsWrap}>
            <BundleDealsCarousels
              bundleId={bundleId}
              returnToPath={returnToPath} />
          </div>
        </BundleDealsProvider>
      </CurrentTimeSlotProvider>
    </BodyWrapper>
  )
}

export default BundleDealsPage;
