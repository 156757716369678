import { useEffect, useState } from "react";

// Map bulk deal minimums by bundle ID for carted items
const useMixMatchBulkCartItems = (bulkItems) => {

  // Consolidate Mix & Match deal item data
  const [dealData,setDealData] = useState();

  useEffect(() => {
    let dealIdMap = new Map();
    if (bulkItems?.length) {
      dealIdMap = bulkItems.reduce((map, item) => {
        const dealEntry = map.get(item.bundle_deal_data.id);
        map.set(item.bundle_deal_data.id,
          dealEntry
            ? {
                quantity: item.quantity +  dealEntry.quantity,
                minimum: item.bundle_deal_data.minimum_quantity,
              }
            : {
                quantity: item.quantity,
                minimum: item.bundle_deal_data.minimum_quantity,
              }
        );
        return map;
      }, new Map());
    }
    setDealData(dealIdMap);
  }, [bulkItems]);

  return dealData;

}

export default useMixMatchBulkCartItems;
