import * as React from "react";

import { ProductPreviewContext } from "../products/ProductPreviewProvider";
import { mapDealProductsByBundleId, mapCategoryNameByBundleId } from "./deal-utils";

export const BundleDealsContext = React.createContext({});

/**
 * This product provider is used for the /mixMatch page,
 * providing the bundles by Id map
 */
const BundleDealsProvider = ({children}) => {

  const { allProducts } = React.useContext(ProductPreviewContext);
  // All dispensary products keyed by bundleId
  const [productsByBundle, setProductsByBundle] = React.useState();
  // Category name for display purposes
  const [categoryNameByBundle, setCategoryNameByBundle] = React.useState();

  React.useEffect(() => {
    if (allProducts) {
      // Map products by shared bundle/bulk tag (Mix & Match)
      setProductsByBundle(
        mapDealProductsByBundleId(allProducts)
      );
    }
  }, [allProducts]);

  React.useEffect(() => {
    if (productsByBundle) {
      setCategoryNameByBundle(
        mapCategoryNameByBundleId(allProducts)
      );
    }
  }, [productsByBundle, allProducts]);

  return (
    <BundleDealsContext.Provider value={{
      productsByBundle,
      categoryNameByBundle,
    }}>
      {children}
    </BundleDealsContext.Provider>
  );
};

export default BundleDealsProvider;
