import * as React from "react";

import StrongText from "../common/StrongText";
import { isSuperDeal } from "../products/useSaleLabelDetails";
import ToolTip from "../common/ToolTip";
import discountIcon from "../../assets/discountGoldIcon.png";

import styles from './SuperDeal.module.css';

const SUPER_DEAL_CANT_BE_COMBINED_MSG_HTML = (
  <>
    <StrongText noWrap noMargin>Promo Codes</StrongText> and
    {' '}<StrongText noWrap noMargin>Referral Credits</StrongText> cannot
    be used on orders that contain
    {' '}<StrongText noWrap noMargin>Super Deal</StrongText> products.
  </>
);

const SuperDealTooltip = ({
  product,
  withStyle={},
  inModal,
}) => {

  // Label needs to fit in narrow moobile carousel card
  const isMobileNonModal = !inModal && window.innerWidth < 600;
  const { per_order_max_quantity } = product;

  return ( isSuperDeal(product)
    ? <div className={styles.tipWrapper} style={withStyle}>
        <ToolTip
          buttonText={isMobileNonModal ? 'Deal Details' : 'Deal Restrictions'}
          buttonIcon={discountIcon}
          buttonStyle={{padding:4, margin:'0 0 -4px -4px'}}
          tipTitle="Super Deal Restrictions"
          toolTipStyle={{maxWidth:250}}
          placement="top"
          inModal={inModal}
          footerText={ per_order_max_quantity
            ? `NOTE: This Super Deal is limited to ${per_order_max_quantity} per order.`
            : null
          }
          enableFlip
          trackingLabel="super_deal_tooltip">
            <>
              The discounts on our <b>Super Deal</b> products are so crazy good
              that {SUPER_DEAL_CANT_BE_COMBINED_MSG_HTML}
            </>
        </ToolTip>
      </div>
    : null
  );
};

export default SuperDealTooltip;
