import * as React from 'react';

import { devLog } from '../util/util';
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";
import { UserContext } from "../providers/UserProvider";
import { useAnalyticsStore } from '../../App';
import { STACKING } from '../layout/stackingConstants';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { navigateTop, navigateTopWithState } from '../routing/router-utils';
import { SIGN_IN_TEXT, SIGN_OUT_TEXT } from '../registration/SignIn';
import ErrorBoundary from '../common/ErrorBoundary';
import NewHomeIcon from '../icons/NewHomeIcon';
import Account from '@material-ui/icons/AccountCircleRounded';
import CreateIcon from '@material-ui/icons/CreateRounded';
import InfoIcon from '@material-ui/icons/InfoRounded';
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';
import LocalOfferIcon from '@material-ui/icons/LocalOfferRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import SmartPhoneIcon from '@material-ui/icons/SmartphoneRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import cannabisIcon from '../../assets/cannabisIcon.png';

import PropTypes from 'prop-types';
import styles from './BurgerMenu.module.css';

const MenuItems = ({ open, setOpen }) => {

  const { trackEvent } = useAnalyticsStore();

  const { user, clearSearchLocation } = React.useContext(UserContext);
  const { emptyCart } = React.useContext(CartItemsContext);

  const realUser = user && !user.isAnonymous;
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;

  const menuRef = React.useRef(null);

  const handleLogout = () => {
    trackEvent(`hamburger_menu_signout_click`);
    signOut(auth).then(() => {
      // provider
      emptyCart();
      // close modal
      setOpen(false);
      // clear address from storage
      clearSearchLocation();
      // return user to home page (don't leave them in /placeOrder)
      navigateTop(`/`);
    // TODO add a modal for errors
    }).catch(error => devLog(error));
  }

  // Close menu on click outside menu
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if ( menuRef && !menuRef.current.contains(event.target) && !event.target.closest('#burgerIcon')) {
        setOpen(false);
      }
    };
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    // remove handler on component unmout
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, setOpen])

  const openStyle = open ? styles.open : '';

  const navigateAndCloseMenu = (path, withContinue) => {
    setOpen(false);
    const trackLabel = path.substring(1).toLowerCase() || 'home';
    trackEvent(`hamburger_menu_${trackLabel}_click`);
    if (withContinue) {
      // include current page in state
      navigateTopWithState(path, {
        returnToPath: `${window.location.pathname}`,
        continuePath: `${window.location.pathname}`
      });
    } else {
      navigateTop(path);
    }
  }

  return (
    <ErrorBoundary>
      <div ref={menuRef} style={{zIndex:STACKING.HAMBURGER_MENU}}
           className={`${styles.menuItems} ${openStyle}`}
           aria-hidden={!isHidden}>
        <span className={styles.menuItem}
            tabIndex={tabIndex} onClick={() => {
              navigateAndCloseMenu(`/`);
          }}>
          <NewHomeIcon inMenu fillColor='#000' size="16px" /> Home
        </span>
        <span className={styles.menuItem}
            tabIndex={tabIndex} onClick={() => {
              navigateAndCloseMenu(`/`);
          }}>
          <img alt="" height={20} style={{verticalAlign:-2}} src={cannabisIcon} /> Shop
        </span>
        {/* links for logged in state */}
        { realUser
          ? <>
              <span className={styles.menuItem}
                tabIndex={tabIndex} onClick={() => {
                  navigateAndCloseMenu(`/account`);
              }}>
                <Account className={styles.itemIcon}/> Your Account
              </span>
              <span className={styles.menuItem}
                tabIndex={tabIndex} onClick={() => {
                  navigateAndCloseMenu(`/orders`);
              }}>
                <ListAltRoundedIcon className={styles.itemIcon}/> Your Orders
              </span>
              <span className={styles.menuItem} tabIndex={tabIndex} onClick={handleLogout}>
                <HighlightOffRoundedIcon className={styles.itemIcon}/> {SIGN_OUT_TEXT}
              </span>
            </>
          : <>
              <span className={styles.menuItem} tabIndex={tabIndex}
                onClick={() => {
                  navigateAndCloseMenu(`/signIn`, true);
                }}>
                  <Account className={styles.itemIcon}/> {SIGN_IN_TEXT}
              </span>
              <span className={styles.menuItem} tabIndex={tabIndex}
                onClick={() => {
                  navigateAndCloseMenu(`/signInPhone`, true);
                }}>
                  <SmartPhoneIcon className={styles.itemIcon}/> Log In by Phone
              </span>
              <span className={styles.menuItem} tabIndex={tabIndex}
                onClick ={() => {
                  navigateAndCloseMenu(`/signUp`, true);
                }}>
                <CreateIcon className={styles.itemIcon} /> Sign Up
              </span>
            </>
        }
        <span className={styles.menuItem} tabIndex={tabIndex}
          onClick={() => {
            navigateAndCloseMenu(`/search`, true);
          }}>
          <SearchRoundedIcon className={styles.itemIcon}/> Search
        </span>
        <span className={`${styles.menuItem} ${styles.menuItemSale}`} tabIndex={tabIndex}
          onClick={() => {
            navigateAndCloseMenu(`/popular/Deals`, true);
          }}>
          <LocalOfferIcon className={`${styles.itemIcon} ${styles.itemIconSale}`}/> Deals
        </span>
        <span className={styles.menuItem} tabIndex={tabIndex}
          onClick={() => {
            navigateAndCloseMenu(`/storeInfo`, true);
          }}>
          <InfoIcon className={styles.itemIcon}/> Store Info
        </span>
      </div>
    </ErrorBoundary>
  )
}

MenuItems.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};

export default MenuItems;
