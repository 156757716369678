import * as React from "react";

import config from "../config";
import { Router, Redirect } from '@reach/router';
import CommerceLoggingProvider from "./analytics/CommerceLoggingProvider";
import AnalyticsDebug from "./analytics/AnalyticsDebug";
import AttributionProvider from "./providers/AttributionProvider";
import RegistrationProvider from "./registration/RegistrationProvider";
import NotificationsProvider from './notifications/NotificationsProvider';
import EmailResponseController from './emailRequest/EmailResponseController';
import FramableLocationSearch from "./address/FramableLocationSearch";
import AeropayProvider from "./payment/AeropayProvider";
import DeliveryOptionsProvider from "./providers/DeliveryOptionsProvider";
import PartnerProvider from "./brand/PartnerProvider";
import DispensaryProvider from "./dispensary/DispensaryProvider";
import DiscountCodeFromUrlMsg from './discounts/DiscountCodeFromUrlMsg';
import ProductCategoryProvider from "./providers/ProductCategoryProvider";
import FilteredProductsProvider from "./products/FilteredProductsProvider";
import GoogleMapsProvider from "./providers/GoogleMapsProvider";
import PromoBannerProvider from "./banners/PromoBannerProvider";
import ModalProvider from "./modal/ModalProvider";
import DiscountCreditAndFeeProvider from "./providers/DiscountCreditAndFeeProvider";
import CartItemsProvider from "./checkout/CartItemsProvider";
import PageLayout from "./common/PageLayout";
import Home from "./home/Home";
import Verify21Modal from './home/Verify21Modal';
import Products from './products/Products';
import BrandProductsPage from "./brand/BrandProductsPage";
import PartnerProductsPage from "./brand/PartnerProductsPage";
import BundleDealsPage from "./deals/BundleDealsPage";
import ProductSearch from "./search/ProductSearch";
import ChatPage from "./intercom/ChatPage";
import GetReferralLink from "./refer/GetReferralLink";
import GetCreditLander from "./refer/GetCreditLander";
import PlaceOrderController from "./viewControllers/PlaceOrderController";
import OrderConfirmation from './orderConfirmation/OrderConfirmation';
import SignIn from "./registration/SignIn";
import PhoneSignIn from "./signInViaPhone/PhoneSignIn";
import SignUpPage from "./registration/SignUpPage";
import RegistrationComplete from "./registration/RegistrationComplete";
import YourAccount from "./account/YourAccount";
import YourOrders from "./orders/YourOrders";
import ReOrderPage from "./reorder/ReOrderPage";
import ProductDetailPage from "./products/ProductDetailPage";
import ProductDetailBySkuPage from "./products/ProductDetailBySkuPage";
import ProductPreviewProvider from "./products/ProductPreviewProvider";
import StoreInfo from "./dispensary/StoreInfo";
import StyleGuide from "./styleguide/StyleGuide";
import SurveyThanksPage from "./survey/SurveyThanksPage";
import PageNotFound from './common/PageNotFound';
import { HelmetProvider } from 'react-helmet-async';

const Application = () => {

  return (
    <HelmetProvider>
    <AttributionProvider>
     <GoogleMapsProvider>
     <CommerceLoggingProvider>
     <RegistrationProvider>
      <DeliveryOptionsProvider>
        <AeropayProvider>
          <ProductCategoryProvider>
           <PartnerProvider>
            <DispensaryProvider>
             <ProductPreviewProvider>
              <FilteredProductsProvider>
               <PromoBannerProvider>
                <NotificationsProvider> {/* Deals: moved up from after CartitemsProvider */}
                 <DiscountCreditAndFeeProvider>
                  <CartItemsProvider>
                   <ModalProvider>
                    <PageLayout>
                     <Verify21Modal />
                     <DiscountCodeFromUrlMsg />
                     <Router>
                       <Products path="/products" />
                       <Products path="/products/:category" />
                       <Products path="/products/:category/:subCategoryName" />
                       <BrandProductsPage path="/brand/:brand" />
                       <BrandProductsPage path="/brand/:brand/:category" />
                       <BrandProductsPage path="/brand/:brand/:category/:subCategory" />
                       <BundleDealsPage path="/mixMatch" />
                       <BundleDealsPage path="/mixMatch/:bundleId" />
                       <PartnerProductsPage path="/partner/:vendorId" />
                       <PartnerProductsPage path="/partner/:vendorId/:category" />
                       <PartnerProductsPage path="/partner/:vendorId/:category/:subCategory" />
                       <ProductSearch path="/search" />
                       <ChatPage path="/chat" />
                       <FramableLocationSearch path="/framedSearch" />
                       <GetReferralLink path="/refer" />
                       <GetCreditLander path="/get50/:encodedReferrer" />
                       <GetCreditLander path="/getCredit/:encodedReferrer" />
                       <PlaceOrderController path="/checkOut" />
                       <OrderConfirmation path="/orderConfirmation" />
                       <SignIn path="/signIn" />
                       <PhoneSignIn path="/signInPhone" />
                       <SignUpPage path="/signUp" />
                       <RegistrationComplete path="/signUpComplete" />
                       <YourAccount path="/account" />
                       <YourOrders path="/orders" />
                       <ReOrderPage path="/reOrder/:orderId" />
                       <StoreInfo path="/storeInfo" />
                       <ProductDetailPage path="/product/:itemId" />
                       <ProductDetailBySkuPage path="/zrProduct/:itemSku" />
                       <EmailResponseController path="/emailResponse" />
                       <StyleGuide path="/styleguide" />
                       <SurveyThanksPage path="/surveyThanks" />
                       <Home path="/" />
                       <Redirect from="/popular/Sale" to="/popular/Deals" noThrow />
                       <Home path="/popular/:category" />
                       <PageNotFound default path="/pageNotFound" />
                     </Router>
                     { config.IS_DEV &&
                       <AnalyticsDebug />
                     }
                    </PageLayout>
                   </ModalProvider>
                  </CartItemsProvider>
                 </DiscountCreditAndFeeProvider>
                </NotificationsProvider>
               </PromoBannerProvider>
              </FilteredProductsProvider>
             </ProductPreviewProvider>
            </DispensaryProvider>
           </PartnerProvider>
          </ProductCategoryProvider>
        </AeropayProvider>
      </DeliveryOptionsProvider>
     </RegistrationProvider>
     </CommerceLoggingProvider>
     </GoogleMapsProvider>
    </AttributionProvider>
    </HelmetProvider>
  );
}

export default Application;
