import { auth } from '../../firebase';

import { dispensaryDetailEndpoint,
         dispensariesEndpoint,
         metadataEndpoint,
         dispensaryProductsEndpoint,
         pickupOptionsEndpoint,
         deliveryOptionsEndpoint,
         dispensaryProductDetailEndpoint,
         productCategoriesEndpoint,
         promosEndpoint } from '../../constants/api-endpoints';

import { trackError } from '../analytics/tracking';
import { devLog } from '../util/util';

// Used in setting default widths
export const DispensaryAsset = {
  LOGO: 'logo',
  HERO: 'hero'
};

// The "All Dispensaries" endpoint is used to request non-product data for all dispensaries
// I'm adding include_inactive=true to help with NEA on-boarding
const allDispensariesRequest = (callback, controller) => {
  if (auth.currentUser) {
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
      fetch(dispensariesEndpoint, {
        method: 'GET',
        headers: {
          'Authorization': idToken
        },
        signal: controller.signal
      }).then(response => response.json())
        .then(data => callback(data))
        .catch(error => console.log(error));
    });
  }
};

// Used to request dispensary details like delivery windows etc.
const dispensaryDetailRequest = (dispensaryId, callback, controller) => {
  if (auth.currentUser) {
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
      const dispensaryEndpoint = dispensaryDetailEndpoint.replace(/\[dispId\]/,dispensaryId);
      fetch(`${dispensaryEndpoint}?include_minimum_order_value_requirements=true`, {
        method: 'GET',
        headers: {
          'Authorization': idToken
        },
        signal: controller.signal
      }).then(response => response.json())
        .then(data => callback(data))
        .catch(error => console.log(error));
    });
  }
};

// e.g. get the dispensary promo text
export const dispensaryEndpointRequest = (endpoint, dispensaryId, callback, controller) => {
  if (auth.currentUser) {
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
      const dispensaryEndpoint = endpoint.replace(/\[dispId\]/,dispensaryId);
      fetch(dispensaryEndpoint, {
        method: 'GET',
        headers: {
          'Authorization': idToken
        },
        signal: controller.signal
      }).then(response => response.json())
        .then(data => callback(data))
        .catch(error => console.log(error));
    });
  }
};

export const fetchPickupOptions = (dispensaryId, callback, controller) => {
  if (auth.currentUser) {
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
      const dispensaryPickupOptionsEndpoint = pickupOptionsEndpoint.replace(/\[dispId\]/, dispensaryId);
      fetch(dispensaryPickupOptionsEndpoint, {
        method: 'GET',
        headers: {
          'Authorization': idToken
        },
        signal: controller.signal
      }).then(response => response.json())
        .then(data => callback(data))
        .catch(error => console.log(error));
    });
  }
};

export const fetchDeliveryOptions = (dispensaryId, callback, controller) => {
  if (auth.currentUser) {
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
      const dispensaryDeliveryOptionsEndpoint = deliveryOptionsEndpoint.replace(/\[dispId\]/, dispensaryId);
      fetch(dispensaryDeliveryOptionsEndpoint, {
        method: 'GET',
        headers: {
          'Authorization': idToken
        },
        signal: controller.signal
      }).then(response => response.json())
        .then(data => callback(data))
        .catch(error => console.log(error));
    });
  }
};

/**
 * Fetch a single dispensary from the all dispensaries endpoint
 *
 * @param {string} dispensaryId - the desired dispensary
 * @param {function} callback
 * @param {AbortController} controller
 */
export const fetchDispensary = async (dispensaryId, callback, controller) => {
  try {
    dispensaryDetailRequest(dispensaryId, callback, controller);
  } catch(error) {
    trackError('error_requesting_dispensary');
    devLog('dispensary fetch failed');
  }
};

/**
 * Fetch all dispensaries from the endpoint
 *
 * @param {function} callback
 * @param {AbortController} controller
 */
export const fetchDispensaries = async (callback, controller) => {
  try {
    if (auth.currentUser) {
      allDispensariesRequest(callback, controller);
    }
  } catch(error) {
    trackError('error_requesting_dispensaries');
    devLog('dispensaries fetch failed');
  }
};

/**
 * Fetch the total cost calc data
 *
 * @param {function} callback
 * @param {AbortController} controller
 */
export const fetchMetaData = async (callback, controller) => {
  try {
    if (auth.currentUser) {
      auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
        fetch(metadataEndpoint, {
          method: 'GET',
          headers: {
            'Authorization': idToken
          },
          signal: controller.signal
        }).then(response => response.json())
          .then(data => callback(data))
          .catch(error => console.log(error));
      });
    }
  } catch(error) {
    trackError('error_requesting_metadata');
    devLog('metadata fetch failed');
  }
};

/**
 * Fetch product categories
 * Executing the callback on a non-200 reponse can cause race conditions so don't do it.
 *
 * SessionReady has already been checked
 */
export const fetchProductCategories = async (callback, controller) => {
  if (auth.currentUser) {
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
      fetch(productCategoriesEndpoint, {
        method: 'GET',
        headers: {
          'Authorization': idToken
        },
        signal: controller.signal
      }).then(response => {
        if (response.status !== 200) {
          trackError(`error_requesting_product_categories_${response.status}`);
          devLog(`Error! ${response.status}`);
        } else {
          response.json().then(data => {
            if (callback) {
              callback(data);
            }
          });
      }}).catch(error => console.log(error));
    });
  };
};

// For debouncing product fetch
let productFetchTask;

/**
 * Fetch products for a single dispensary
 * Executing the callback on a non-200 reponse can cause race conditions so don't do it.
 *
 * PublicFetch: Switch to no auth fetch for performance, 6/24/24
 */
export const fetchProducts = async (fetchParams, callback, controller) => {

  const productRequest = (fetchParams, callback, controller) => {
    // if (auth.currentUser) {
      // auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
        let paramObj = undefined;
        const { category_name, vendor_id } = fetchParams;
        /* NOTE: You can only pass one! */
        if (category_name) {
          paramObj = { category_name };
        } else if (vendor_id) {
          paramObj = { vendor_id };
        }
        const paramString = paramObj ? new URLSearchParams(paramObj).toString(): '';
        fetch(`${dispensaryProductsEndpoint}?${paramString}`, {
          method: 'GET',

          signal: controller.signal
        }).then(response => {
          if (response.status !== 200) {
            trackError(`error_requesting_dispensary_products_${response.status}`);
            devLog(`Error! ${response.status}`);
          } else {
            response.json().then(data => {
              if (callback) {
                callback(data);
              }
            });
        }}).catch(error => console.log(error));
  };

  try {
    window.clearTimeout(productFetchTask);
    productFetchTask = window.setTimeout(() => {
      devLog('REQUESTING PRODUCT DATA');
      productRequest(fetchParams, callback, controller);
    }, 100);
  } catch(error) {
    trackError('error_requesting_dispensary_products');
    devLog('dispensary product fetch failed');
  }
};

// Fetch a single product
export const fetchProductDetail = async (productId, callback, controller) => {
  try {
    if (auth.currentUser) {
      auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
        const productDetailUrl = dispensaryProductDetailEndpoint.replace(/\[productId\]/, productId);
        // TODO: ?product_id= not needed after API rollout
        fetch(productDetailUrl, {
          method: 'GET',
          headers: {
            'Authorization': idToken
          },
          signal: controller.signal
        }).then(response => response.json())
          .then(data => callback(data))
          .catch(error => console.log(error));
      });
    }
  } catch(error) {
    trackError('error_requesting_product_detail');
    devLog('dispensary product detail fetch failed');
  }
};

export const fetchPromos = async (dispensary_id, callback, controller) => {
  if (auth.currentUser) {
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
      fetch(`${promosEndpoint}?dispensary_id=${dispensary_id}`, {
        method: 'GET',
        headers: {
          'Authorization': idToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        signal: controller.signal
      }).then(response => response.json())
        .then(data => callback(data))
        .catch(error => console.log(error));
    });
  }
};
