import * as React from 'react';

import { CREDIT_LABELS } from './DisplayCreditDetails';
import { dollarDisplay } from '../providers/OrderPricingProvider';
import { OrderSection } from '../checkout/PlaceOrder';
import { DiscountCreditAndFeeContext } from '../providers/DiscountCreditAndFeeProvider';
import BulkCartWarning from '../bundles/BulkCartWarning';
import SuperDealCartWarning from '../deals/SuperDealCartWarning';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

import styles from './ReferralCredit.module.css';

const DisplayCreditAtCheckout = () => {

  // Remove credit
  const removeIconStyle = {
    verticalAlign: -5,
    fontSize: 20,
    color: 'var(--zrPrimaryVeryDark)',
    margin: '0 2px'
  };

  const { activeCredit, setActiveCredit } = React.useContext(DiscountCreditAndFeeContext);
  const { reason, referral_name, referrer_name, value } = activeCredit || {};

  return ( activeCredit
      ? <OrderSection title="CREDITS">
          <div className={styles.credit}>
            <div className={styles.type}>
              {CREDIT_LABELS[reason]}
            </div>
            <div className={styles.user}>
              { reason === 'referrer'
                ? referral_name /* you received from */
                : referrer_name /* you referred and got from */
              }
            </div>
            <div className={styles.amount}>-{dollarDisplay(value.usa_cents)}</div>
            <span className={styles.remove} onClick={() => {
              setActiveCredit(undefined);
            }}>
              Remove
              <HighlightOffRoundedIcon style={removeIconStyle} />
            </span>
          </div>
          <div className={styles.warnings}>
            <BulkCartWarning
              activeCredit={activeCredit}
              withClass={styles.alert} />
            <SuperDealCartWarning
              activeCredit={activeCredit}
              withClass={styles.alert} />
          </div>
        </OrderSection>
      : null
  );
};

export default DisplayCreditAtCheckout;
